import {
  toGetSeekingList,
  toGetUserNames,
  toGetTagData,
  getJsonConfig,
  toGetModalList,
  getPresetQuestion,
  likeStatus,
} from "@/apis/common";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Carousel,
  Input,
  Segmented,
  Tooltip,
  ConfigProvider,
  message,
} from "antd";
import Styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import SendPic from "@/assets/chat/send-light.svg";
import SuperAssistant from "@/assets/common/superAssistantBg.svg";

import { useNavigate, useSearchParams } from "react-router-dom";
import ToolItem from "@/components/ToolItems";
import CardItem from "@/components/CardItem";
import { unique } from "@/utils/common";
import KnowledgeIcon from "@/assets/special/b_book_line.svg";
import UploadFilesList from "@/components/uploadFilesList";
import FileUpload from "@/components/FileUpload";
import Workflow from "@/components/ChatWorkflow";
import RelateKnowledgeBaseModal from "@/components/relateKnowledgeBaseModal";

import useLoginHooks from "@/hooks/useLoginHooks";
import store, { loginOption } from "@/store";
import classNames from "classnames";
import React from "react";
import { openModal } from "@/store/reducer/loginConfig";
import { customFileUpload } from "@/utils/upload";

import CustomInput from "@/components/ChatBox/superAssistant/component/input";

const Home = () => {
  const [newAssistantList, setNewAssistantList] = useState<any[]>([]);
  const [isShowWork, setIsShowWork] = useState(false);
  const [chatMsg, setChatMsg] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tagNameObj, setTagNameObj] = useState({});
  const [userNameObj, setUserNameObj] = useState({});
  const [userNameObjCard, setUserNameObCard] = useState({});
  const [agentList] = useState([
    {
      show_name: "关联知识库",
      icon: KnowledgeIcon,
    },
  ]);
  const agentListWrapRef = useRef(null);
  const isMobile = window?.innerWidth <= 600;

  const [showTopic, setShowTopic] = useState(false);
  const [topicList, setTopicList] = useState<any[]>([]);
  const [fileList, setFileList] = useState<any>([]);
  const [pastedFiles, setPastedFiles] = useState<any>([]);
  const { isLogin } = useLoginHooks();
  const [deleteFileList, setDeleteFileList] = useState<any>([]);

  const relateKnowledgeBaseModalRef = useRef<any>();
  const [curFolderList, setCurFolderList] = useState<any>([]);
  const [homeQuestionList, setHomeQuestionList] = useState<any[]>([]);
  const [cardList, setCardList] = useState<any[]>([]);
  const [isReload, setIsReload] = useState(false);
  const [likeStatusObj, setLikeStatusObj] = useState({});
  const [superType, setSuperType] = useState("llm_chat");
  const [subpage, setSubpage] = useState("talk");

  // TODO: 暂时隐藏专家模式 在这个地方做一个默认处理 防止老用户存在本地storage 导致意料之外触发专家模式
  useEffect(() => {
    Promise.resolve(localStorage.setItem("superType", "llm_chat")).then(() => {
      setSuperType("llm_chat");
    });
  }, []);

  const [params] = useSearchParams();
  const pattern = params.getAll("pattern")?.[0];

  const getFilesList = (fileArr: any) => {
    setFileList(fileArr);
  };

  const clearWoke = (fileArr: any) => {
    setIsShowWork(false);
  };

  const onChat = (
    prompt?: string,
    fileList?: any,
    isDP?: boolean,
    internetActive?: boolean
  ) => {
    if (!isLogin) {
      store.dispatch(loginOption.openModal());
      return;
    }
    if (!chatMsg && !prompt) {
      return;
    }
    let data = chatMsg;
    if (prompt) {
      data = prompt;
    }
    if (fileList && fileList.length) {
      localStorage.setItem("chatFiles", JSON.stringify(fileList));
    }
    let url = `/SuperAssistantChat?chatMsg=${encodeURIComponent(data)}`;
    let knowledgeParam = "";
    if (curFolderList.length > 0) {
      knowledgeParam = JSON.stringify(curFolderList);
    }
    if (isDP) {
      url = url + "&isDP=true";
      if (internetActive) {
        url = url + "&internetActive=true";
      }
      navigate(url);
    } else {
      navigate(url, {
        state: { knowledge: knowledgeParam, pattern: superType },
      });
    }
    setChatMsg("");
  };

  const handleLinkClick = () => {
    if (isLogin) {
      relateKnowledgeBaseModalRef?.current?.show();
    } else {
      store.dispatch(openModal());
    }
  };

  const chatWorkflowSend = () => {
    navigate("/SuperAssistantChat?messageType=workflow");
  };

  useEffect(() => {
    if (isLogin) {
      getTopic();
    }
  }, [isLogin]);

  useEffect(() => {
    getQuestionList();
    getListId();
  }, []);

  const chatFiles = useMemo(() => {
    return [...fileList, ...pastedFiles].filter((file: any) => {
      return !deleteFileList.some((item: any) => item.uid === file.uid);
    });
  }, [fileList, pastedFiles, deleteFileList]);

  useEffect(() => {
    localStorage.setItem("chatFiles", JSON.stringify(chatFiles));
  }, [chatFiles]);

  const getTopic = async () => {
    toGetModalList({
      page_number: 1,
      page_size: 20,
      llm_id: 1000,
    }).then((res) => {
      if (res.data && res.data.status === 200) {
        setTopicList(res.data.data);
      }
    });
  };
  const getDateLabel = (dateString: string) => {
    const now = new Date();
    const date = new Date(dateString);
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const thisWeekStart = new Date(today);
    thisWeekStart.setDate(today.getDate() - today.getDay());
    const thisMonthStart = new Date(now.getFullYear(), now.getMonth(), 1);

    if (date >= today) return "今天";
    if (date >= yesterday) return "昨天";
    if (date >= thisWeekStart) return "本周";
    if (date >= thisMonthStart) return "本月";
    return "更早";
  };

  const getListId = () => {
    getJsonConfig({
      keys: ["home_page_config"],
    }).then((res: any) => {
      if (res && res.data && res.data.status === 200) {
        let list = res.data.data.home_page_config;
        let cardList: any = [];
        let itemList: any = [];
        let ids = [...list.ai_assistant_ids, ...list.ai_knowledge_ids];
        toGetSeekingList({
          ids,
        }).then((res: any) => {
          if (res && res.data && res.data.status === 200) {
            res.data.data.map((item: any) => {
              if (list.ai_assistant_ids.includes(item.id)) {
                itemList.push(item);
              }
              if (list.ai_knowledge_ids.includes(item.id)) {
                cardList.push(item);
              }
            });
            setNewAssistantList(itemList);
            getNewAssistant(itemList);
            getSeekingList(cardList);
            setCardList(cardList);
          }
        });
      }
    });
  };

  const getNewAssistant = async (data: any) => {
    let tagList: Array<number> = [];
    data.forEach((item: any, index: number) => {
      if (item.tags && item.tags.length) {
        tagList = [...tagList, ...item.tags];
      }
    });
    let userIdsList: Array<number> = [];
    let agentIdList: Array<number> = [];
    data.forEach((item: any, index: number) => {
      if (item.author) {
        userIdsList = [...userIdsList, item.author];
      }

      if (item && item.agent_id) {
        agentIdList = [...agentIdList, item.id];
      }
    });
    let newUserNameObj: any = {};
    if (userIdsList.length) {
      toGetUserNames({ ids: unique(userIdsList) }).then((res: any) => {
        if (res.data && res.data.status === 200 && res.data.data.length) {
          res.data.data.forEach((item: any, index: number) => {
            newUserNameObj[item.id] = item.username;
          });

          setUserNameObj(newUserNameObj);
        }
      });
    }
    if (tagList.length) {
      toGetTagData({ ids: unique(tagList) }).then((res: any) => {
        if (res.data && res.data.status === 200 && res.data.data.length) {
          const tagObj: any = {};

          res.data.data.forEach((item: any, index: number) => {
            tagObj[item.id] = item.name;
          });
          setTagNameObj(tagObj);
        }
      });
    }
  };

  const getSeekingList = (data: any) => {
    let tagList: Array<number> = [];
    let userIdsList: Array<number> = [];
    let agentIdList: Array<number> = [];

    data.forEach((item: any, index: number) => {
      if (item && item?.tags && item?.tags.length) {
        tagList = [...tagList, ...item.tags];
      }
      if (item && item.author) {
        userIdsList = [...userIdsList, item.author];
      }
      if (item && item.agent_id) {
        agentIdList = [...agentIdList, item.id];
      }
    });

    let newUserNameObj: any = { ...userNameObj };
    let newLikeStatusObj: any = { ...likeStatusObj };
    let tagObj: any = { ...tagNameObj };

    if (agentIdList.length) {
      likeStatus({
        pub_agent_ids: agentIdList,
      }).then((res: any) => {
        if (res.data && res.data.status === 200) {
          res.data.data.forEach((item: any, index: number) => {
            newLikeStatusObj[item.pub_agent_id] = item;
          });
        }
      });

      setTimeout(() => {
        setLikeStatusObj(newLikeStatusObj);
      });
    }

    if (userIdsList.length) {
      toGetUserNames({ ids: unique(userIdsList) }).then((res: any) => {
        if (res.data && res.data.status === 200 && res.data.data.length) {
          res.data.data.forEach((item: any, index: number) => {
            newUserNameObj[item.id] = item.username;
          });

          setUserNameObCard(newUserNameObj);
        }
      });
    }
  };

  // 对历史对话做日期分组
  const groupedTopics: any = {};

  if (isMobile) {
    topicList.forEach((item) => {
      const label = getDateLabel(item.first_question_time);
      if (!groupedTopics[label]) {
        groupedTopics[label] = [];
      }
      groupedTopics[label].push(item);
    });
  }

  const getQuestionList = () => {
    getPresetQuestion({
      // size: 4,
    }).then((res: any) => {
      if (res && res.data && res.data.status === 200) {
        setHomeQuestionList(res.data.data);
      }
    });
  };

  const questionRender = () => {
    return (
      <div className={Styles.empty_home_page_recommand}>
        <div className={Styles.questions_title}>
          <span>试着问一下这些问题</span>
          <div className={Styles.questions_refresh} onClick={getQuestionList}>
            <div
              className={classNames(Styles.img, "icon-again_line", "iconfont")}
            ></div>
            换一换
          </div>
        </div>
        {homeQuestionList.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className={classNames(
                Styles.empty_home_page_recommand_item,
                "magictime",
                isReload
                  ? index % 2 === 0
                    ? "spaceInLeft"
                    : "spaceInRight"
                  : "vanishIn"
              )}
              style={{
                animationDelay: isReload ? "unset" : `${0.2 * index}s`,
                animationDuration: isReload ? "0.5s" : "1s",
              }}
              onClick={() => {
                onChat(item.prompt);
              }}
            >
              <div className={Styles.empty_home_page_recommand_info}>
                <span>{item.title}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundImage: `url(${SuperAssistant})`,
        backgroundSize: "cover",
      }}
      className={Styles.homePage}
    >
      <div
        className={classNames(
          showTopic ? Styles.showTopic : Styles.hideTopic,
          Styles.topicList
        )}
      >
        <div className={Styles.topicListHeader}>
          {t("历史对话")}
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setShowTopic(false);
            }}
            className={classNames(
              "iconfont",
              "icon-size24",
              "icon-retract_line"
            )}
          ></div>
        </div>
        <div className={classNames(Styles.topicListContent, "scroll-hide")}>
          {topicList.map((item, index) => {
            let first_question = "";
            try {
              const jsonFirstQuestion = JSON.parse(item.first_question);
              first_question = jsonFirstQuestion?.filter(
                (item: any) => item?.type === "text"
              )[0]?.text;
            } catch (e) {
              first_question = item.first_question;
            }
            const htmlContent = {
              __html: first_question.replace(/\n/g, "<br>"),
            };
            return (
              <div
                className={Styles.topicItem}
                key={index}
                onClick={() => {
                  localStorage.removeItem("chatMsg");
                  localStorage.removeItem("chatWorkFlowData");
                  navigate("/SuperAssistantChat?topicId=" + item?.topic_id);
                }}
              >
                <div className={Styles.title}>
                  <p style={{ whiteSpace: "pre-wrap" }}>{first_question}</p>
                </div>
                <div className={Styles.time}>
                  {item?.first_question_time &&
                    new Date(item?.first_question_time).toLocaleString()}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {!isMobile && !showTopic && (
        <div
          onClick={() => {
            setShowTopic(true);
          }}
          className={Styles.chatHistory}
        >
          <span
            className={classNames(
              "iconfont",
              "icon-history_line",
              Styles.chatHistorySpan
            )}
          ></span>
        </div>
      )}
      <div className={classNames(Styles.chatWrap, "scroll-hide")}>
        <div className={Styles.chat}>
          {isMobile && (
            <>
              {/* {subpage === "talk" ? (
                <Segmented
                  className={Styles.pageChoser}
                  options={[
                    { label: "简洁", value: "llm_chat" },
                    { label: "专家(beta)", value: "super_assistant" },
                  ]}
                  onChange={(value) => {
                    if (isLogin) {
                      setSuperType(value);
                      localStorage.setItem("superType", value);
                    } else {
                      store.dispatch(openModal());
                    }
                  }}
                />
              ) : null} */}
              <div
                onClick={() => {
                  subpage === "talk"
                    ? setSubpage("history")
                    : setSubpage("talk");
                }}
              >
                <span
                  className={classNames(
                    Styles.dialogue,
                    "iconfont",
                    subpage !== "talk" ? "icon-chat_line" : "icon-history_line",
                    Styles.dialogue_m
                  )}
                ></span>
              </div>
            </>
          )}
          {subpage === "talk" && (
            <>
              {isMobile ? (
                <div className={Styles.mobileQuestion}>{questionRender()}</div>
              ) : (
                <div>
                  <span className={Styles.chatTitle}>
                    <span>Your</span>{" "}
                    <span className={Styles.chatTitleColor}>AI-Powered</span>{" "}
                    <span>Productivity</span>
                  </span>
                </div>
              )}
              {!isMobile && (
                <ConfigProvider
                  theme={{
                    components: {
                      Segmented: {
                        itemSelectedBg: "#1d2129",
                        itemSelectedColor: "#ffffff",
                        trackBg: "#fff",
                        itemActiveBg: "#fff",
                        itemHoverColor: "#4e5969",
                        itemHoverBg: "#fff",
                      },
                    },
                  }}
                >
                  {/* <Segmented
                    className={Styles.pattern}
                    options={[
                      { label: "简洁", value: "llm_chat" },
                      { label: "专家(beta)", value: "super_assistant" },
                    ]}
                    onChange={(value) => {
                      if (isLogin) {
                        setSuperType(value);
                        localStorage.setItem("superType", value);
                      } else {
                        store.dispatch(openModal());
                      }
                    }}
                    value={superType}
                  /> */}
                </ConfigProvider>
              )}
              <div className={Styles.input_wrap}>
                <CustomInput
                  onChat={(
                    value: string,
                    fileList: any,
                    isDP: boolean,
                    internetActive: boolean
                  ) => {
                    onChat(value, fileList, isDP, internetActive);
                  }}
                  chatWorkflowSend={chatWorkflowSend}
                  isOnBottom
                  knowledgeList={curFolderList}
                  setKnowledgeList={setCurFolderList}
                  curSuperType={superType}
                />
              </div>
            </>
          )}

          {isMobile ? null : (
            <>
              <div>{questionRender()}</div>
              <div className={Styles.page_recommend_wrap}>
                <div
                  style={{
                    width: "100%",
                  }}
                  ref={agentListWrapRef}
                  className={
                    showTopic ? Styles.page_recommend_item_showTopic : ""
                  }
                >
                  <div className={Styles.empty_home_page_question_change}>
                    <div className={Styles.page_recommend_title}>
                      {t("AI助理")}
                    </div>
                    <div
                      onClick={() => {
                        navigate("/aitool");
                      }}
                      className={Styles.page_recommend_title_more}
                    >
                      {t("查看更多")}
                      <div
                        className={classNames(
                          "iconfont",
                          "icon-a-arrowright_line",
                          "icon-size24"
                        )}
                      ></div>
                    </div>
                  </div>
                  <div className={Styles.page_recommend_wrap_right}>
                    {newAssistantList.map((item, index) => {
                      return (
                        <div className={Styles.page_recommand_item} key={index}>
                          <ToolItem
                            key={index}
                            agentInfo={item}
                            tagNameObj={tagNameObj}
                            userNameObj={userNameObj}
                            // collectSwitch={true}
                            likeStatusObj={{}}
                            setLikeStatusObj={() => {}}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              {cardList && cardList.length > 0 ? (
                <div
                  className={classNames(
                    Styles.page_recommend_wrap,
                    Styles.page_recommend_wrap_card
                  )}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                    ref={agentListWrapRef}
                    className={
                      showTopic ? Styles.page_recommend_item_showTopic : ""
                    }
                  >
                    <div className={Styles.empty_home_page_question_change}>
                      <div className={Styles.page_recommend_title}>
                        {t("AI知识")}
                      </div>
                      <div
                        onClick={() => {
                          navigate("/aicard");
                        }}
                        className={Styles.page_recommend_title_more}
                      >
                        {t("查看更多")}
                        <div
                          className={classNames(
                            "iconfont",
                            "icon-a-arrowright_line",
                            "icon-size24"
                          )}
                        ></div>
                      </div>
                    </div>
                    <div className={Styles.page_recommend_wrap_right}>
                      {cardList.map((item, index) => {
                        return (
                          <div
                            className={Styles.page_recommand_item}
                            key={index}
                          >
                            <CardItem
                              agentInfo={item}
                              tagNameObj={tagNameObj}
                              userNameObj={userNameObjCard}
                              likeStatusObj={likeStatusObj}
                              setLikeStatusObj={setLikeStatusObj}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
      {subpage === "history" && (
        <div className={Styles.historyPage}>
          {Object.keys(groupedTopics).map((label) => (
            <React.Fragment key={label}>
              <div className={Styles.historyDateLabel}>{label}</div>
              {groupedTopics[label].map((item: any, index: number) => {
                let first_question = "";
                try {
                  const jsonFirstQuestion = JSON.parse(item.first_question);
                  first_question = jsonFirstQuestion?.filter(
                    (item: any) => item?.type === "text"
                  )[0]?.text;
                } catch (e) {
                  first_question = item.first_question;
                }

                return (
                  <div
                    key={index}
                    className={Styles.mobileChatCard}
                    onClick={() => {
                      localStorage.removeItem("chatMsg");
                      localStorage.removeItem("chatWorkFlowData");
                      navigate("/SuperAssistantChat?topicId=" + item?.topic_id);
                    }}
                  >
                    <div className={Styles.mobileChatCardTitle}>
                      {first_question}
                    </div>
                    <div className={Styles.time}>
                      {item?.first_question_time &&
                        new Date(item?.first_question_time).toLocaleString()}
                    </div>
                  </div>
                );
              })}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default Home;
