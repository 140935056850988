import baseURL from "@/request/getBaseURL";

export const getImageUrl = (url?: string) => {
  const regex = new RegExp("^((https|http)?://)");
  let imgReg = new RegExp(
    /^\s*data:(?:[a-z]+\/[a-z0-9-+.]+(?:;[a-z-]+=[a-z0-9-]+)?)?(?:;base64)?,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*?)\s*$/i
  );

  let mediaReg = new RegExp(/media/);

  if (!url) {
    return "";
  }

  if (imgReg.test(url)) {
    return url;
  }

  if (regex.test(url)) {
    return url;
  } else {
    let imgurl = baseURL + url;
    if (!mediaReg.test(url)) {
      imgurl = baseURL + "/media/" + url;
    }
    imgurl.replaceAll("//", "/");
    return imgurl;
  }
};

export const unique = (arr: Array<any>) => {
  return Array.from(new Set(arr));
};

export const numberFormat = (num?: number) => {
  if (!num) {
    return num;
  }

  if (num >= 1000) {
    return (num / 1000).toFixed(1) + "k";
  } else {
    return num;
  }
};

export const getModalName = (id: number) => {
  if (id === 1035) {
    return "GPT3.5";
  } else if (id === 1040) {
    return "GPT4.0";
  }
};

export const createImageUrl = (filePath: string, endPointType = "b") => {
  const baseUrl =
    "https://syngents-userfile-1321707147.cos.ap-beijing.myqcloud.com/web";
  return `${baseUrl}/${endPointType}/assets/${filePath}`;
};

export const isMobile = window?.innerWidth <= 500;
