import { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./index.module.scss";
import { message } from "antd";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { getShareUuid, toCreateModal } from "@/apis/common";

import "react-typed/dist/animatedCursor.css";
import baseWsURL from "@/request/getWsBaseURL";

import hljs from "highlight.js";
import Cookies from "js-cookie";
import useLoginHooks from "@/hooks/useLoginHooks";
import "highlight.js/styles/vs2015.css";
import Content from "../components/Content";
import store, { loginOption } from "@/store";
import _, { cloneDeep } from "lodash";
import { useSearchParams, useLocation } from "react-router-dom";
import { getRandomError } from "@/common/errorMsg";
import UploadFilesList from "../../uploadFilesList";
import { createImageUrl } from "@/utils/common";
import DynamicForm from "../../ChatWorkflow/components/DynamicForm";
import ShareModal from "../components/ShareModal";
import CustomInput from "./component/input";

const ChatgptIcon = createImageUrl("chat/superAssistant.png");

const agentB_proBg = createImageUrl("chat/superAssistant.png");

type IChatBox = {
  history?: any;
  messageType?: string;
  isDP?: boolean;
  internetActive?: boolean;
};

let ws: any = null;
const ChatBox = ({ history, messageType, isDP, internetActive }: IChatBox) => {
  const { t } = useTranslation();
  const [chatMsg, setChatMsg] = useState("");
  const [sendNow, setSendNow] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [shareTitle, setShareTitle] = useState("");
  const [chatData, setChatData] = useState(history);
  const boxRef: any = useRef();
  const [btnLoading, setBtnLoading] = useState(false);
  const token = localStorage.getItem("token") || Cookies.get("token");

  const [current, setCurrent] = useState<any>({
    id: 1000,
    model_ver_id: 1040,
    name: t("超级助理"),
    avatar_url: ChatgptIcon,
  });

  const [deleteFileList, setDeleteFileList] = useState<any>([]);
  const [fileList, setFileList] = useState<any>([]);
  const [pastedFiles, setPastedFiles] = useState<any>([]);

  const [isHistoryWokeFlow, setIsHistoryWokeFlow] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [isShowDynamicForm, setIsShowDynamicForm] = useState(false);
  const [userInputForm, setUserInputForm] = useState(Number);

  const clearDynamicForm = () => {
    setIsShowDynamicForm(false);
  };

  const chatFiles = useMemo(() => {
    return [...fileList, ...pastedFiles].filter((file: any) => {
      return !deleteFileList.some((item: any) => item.uid === file.uid);
    });
  }, [fileList, pastedFiles, deleteFileList]);

  const [knowledgeList, setKnowledgeList] = useState([]);

  const { isLogin } = useLoginHooks();
  const location = useLocation();

  const [params] = useSearchParams();
  const [topicId, setTopicId] = useState(params.getAll("topicId")?.[0]);

  const handleShare = (value: boolean) => {
    // 传入参数则打开分享弹窗 不穿参数或者传入false则关闭弹窗
    if (value) {
      getShareUuid(value).then((res) => {
        if (res.data?.status === 200) {
          const currentLocation = window.location;
          const baseUrl = `${currentLocation.protocol}//${
            currentLocation.hostname
          }${currentLocation.port ? `:${currentLocation.port}` : ""}`;
          const shareUrl = `${baseUrl}/share?uuid=${res.data.data.uuid}`;
          setShareUrl(shareUrl);
          setShareTitle(res.data.data.chat_title);
        } else {
          message.error("生成链接失败啦, 请稍后重试~");
        }
      });
      setIsShareModalVisible(true);
    } else {
      setIsShareModalVisible(false);
      setShareUrl("");
      setShareTitle("");
    }
  };

  let isFinished = true;
  const onLoginAndRegister = () => {
    store.dispatch(loginOption.openModal());
  };

  const tryAgain = (index: number, item?: any) => {
    const data = [...chatData].slice(0, index);
    const lastMsg = _.findLast(data, (item) => item.role === 1);

    // 如果是工作流
    if (item.content_type === "tool") {
      chatWorkflowSend();
      return;
    }

    if (lastMsg.role === 1) {
      if (typeof lastMsg.content === "string") {
        setChatMsg(lastMsg.content);
        setSendNow(true);
        goChatBottom();
        return;
      }

      if (Array.isArray(lastMsg.content)) {
        setChatMsg(lastMsg.content[0].text);
        setFileList(
          lastMsg.content.slice(1).map((item: any) => {
            return {
              ...item,
              response: { data: { file_url: item?.url } },
            };
          })
        );
        setTimeout(() => {
          setSendNow(true);
          goChatBottom();
        }, 100);
      }
    }
  };

  const chatWorkflowSend = (topic_id?: string | number) => {
    const data = localStorage.getItem("chatWorkFlowData")
      ? JSON.parse(localStorage.getItem("chatWorkFlowData") as string)
      : {};
    if (data.id) {
      setIsHistoryWokeFlow(false);
    }
    const state = location.state;
    const newChatData = [...chatData];
    const inputs = data.inputs;

    const originData = data?.originData || [];
    const result = originData
      .map((item: any) => {
        const variable = item.variable;
        if (inputs[variable] !== undefined) {
          return `${item.label}: ${inputs[variable]}`;
        } else {
          return `${item.label}: `;
        }
      })
      .filter((item: any) => item !== "")
      .join(";\n");

    let msgObj: any = {
      message: "",
      topic_id: topic_id || topicId,
      history: newChatData.slice(0, newChatData.length).slice(-10),
      is_save_msg: token ? true : false,
      chat_mode: "ai_workflow",
      is_stream: true,
      workflow: {
        // 如果使用了工作流，就传此参数。否则不传或传 {}
        id: data.id, // Django中workflow的自增ID
        inputs, // dify 工作流的参数
      },
      model: "gpt-4o",
    };

    // 超级助理是专家进来对话，弹出啊工作流，增加 message 内容字段传参，放弃 workflow 字段传参。
    if (state?.pattern === "super_assistant") {
      msgObj.message = result;
    }

    newChatData.push(
      {
        role: 1,
        content: result,
      },
      {
        role: 2,
        content: " ",
        isLoading: true,
      }
    );

    setChatData(newChatData);
    clearDynamicForm();
    if (ws && ws.readyState === 1) {
      //开始通信时的处理
      ws.send(JSON.stringify(msgObj));
    } else {
      unConnectWs();
      setTimeout(() => {
        connectWs(() => {
          ws && ws.send(JSON.stringify(msgObj));
        });
      });
    }
  };

  useEffect(() => {
    setChatData(history);
  }, [history]);

  useEffect(() => {
    if (!!sendNow) {
      sendMsg();
      setSendNow(false);
    }
  }, [sendNow]);

  useEffect(() => {
    if (messageType === "workflow") {
      unConnectWs();
      connectWs(() => {
        if (!topicId) {
          getTopic(chatWorkflowSend);
        }
      });
      return;
    }
  }, [messageType]);

  const chatMsg1 = localStorage.getItem("chatMsg");
  useEffect(() => {
    if (chatMsg1) {
      unConnectWs();
      connectWs(() => {
        setChatMsg(chatMsg1);
        setSendNow(true);
        localStorage.removeItem("chatMsg");
      });
    }
  }, [chatMsg1, messageType]);

  const highlightCode = () => {
    setTimeout(() => {
      if (boxRef?.current && boxRef?.current?.querySelectorAll) {
        const preEl = boxRef?.current?.querySelectorAll("pre code");
        preEl.forEach((el: any) => {
          hljs.highlightElement(el);
        });
      }
    }, 0);
  };

  const onStopChatClick = () => {
    unConnectWs();
    setBtnLoading(false);
    const newChatData = [...chatData];
    newChatData.forEach((item: any) => {
      item.isLoading = false;
      try {
        if (
          item?.role === 2 &&
          typeof item?.content !== "string" &&
          !!item?.content?.steps?.length &&
          item?.content?.steps?.[item?.content?.steps?.length - 1]?.status ===
            "running"
        ) {
          item.content.steps[item?.content?.steps?.length - 1].status =
            "stopped";
          item.content.result_status = "stopped";
        }
      } catch (error) {
        console.log(error);
      }
    });
    setChatData(newChatData);
    highlightCode();
    setTimeout(() => {
      connectWs();
    }, 200);
  };

  const connectWs = (func?: any) => {
    const token_string = `?token=${token}`;
    ws = new WebSocket(
      `${baseWsURL}/ws/agent_chat${token ? token_string : ""}`
    );

    ws.onopen = function (event: any) {
      if (func) {
        func();
      }
    };

    ws.onclose = function (event: any) {
      setBtnLoading(false);
      console.log("wsclose");
      highlightCode();
    };

    ws.onmessage = (res: any) => {
      const data = JSON.parse(res.data);
      setBtnLoading(true);
      if (data.status === 40001) {
        setBtnLoading(false);

        onLoginAndRegister();
      } else if (data.status === 40000) {
        setChatData((chatData: any) => {
          const newChatData = [...chatData];
          newChatData.pop();
          message.error("参数错误");
          return newChatData;
        });
      } else if (data.status === 20052) {
        // 工作流弹窗
        setIsShowDynamicForm(true);
        setUserInputForm(data.user_input_form);
        setBtnLoading(false);
        highlightCode();
        setChatData((chatData: any) => {
          const newChatData = [...chatData];
          newChatData.pop();
          return newChatData;
        });
      } else if (data.status === 200 && data.from !== "userproxy" && data) {
        let { is_finished, content, content_type } = data;
        if (isFinished) {
          setChatData((chatData: any) => {
            const newChatData = [...chatData];
            // 如果上一条数据为 is_finished，追加一条新的消息展示
            if (newChatData[newChatData.length - 1].is_finished) {
              newChatData.push({
                ...data,
                content_type,
                isLoading: false,
              });
            } else {
              newChatData[newChatData.length - 1] = {
                ...data,
                content_type,
                isLoading: false,
              };
            }
            // newChatData.push({ ...data, content_type });
            return newChatData;
          });
          isFinished = is_finished;
        } else {
          if (content_type === "text") {
            setChatData((chatData: any) => {
              const newChatData = [...chatData];
              newChatData[newChatData.length - 1] = {
                ...data,
                content: newChatData[newChatData.length - 1].content + content,
                content_type,
                isLoading: true,
              };
              return newChatData;
            });
          } else if (content_type === "search") {
            setChatData((chatData: any) => {
              const newChatData = [...chatData];
              newChatData[newChatData.length - 1] = {
                ...data,
                content: {
                  ...data?.content,
                  answer:
                    newChatData[newChatData.length - 1]?.content?.answer +
                    data?.content?.answer,
                  content_type,
                },
                isLoading: true,
              };
              return newChatData;
            });
          } else if (content_type === "tool") {
            setChatData((chatData: any) => {
              const newChatData = [...chatData];
              newChatData[newChatData.length - 1] = {
                ...data,
                content: {
                  ...data?.content,
                  result:
                    newChatData[newChatData.length - 1]?.content?.result +
                    data?.content?.result,
                  content_type,
                  think:
                    newChatData[newChatData.length - 1]?.content?.think +
                    data?.content?.think,
                },
                isLoading: true,
              };
              return newChatData;
            });
          }
          isFinished = is_finished;
        }
      }

      if ((data.status && data.status === 20050) || data.status === 50001) {
        setChatData((chatData: any) => {
          const newChatData = [...chatData];
          newChatData.forEach((item: any) => {
            item.isLoading = false;
          });
          const temp = cloneDeep(newChatData[newChatData.length - 1]);
          if (typeof temp?.content === "string" && temp.content.trim() === "") {
            newChatData.pop();
          } else {
            newChatData[newChatData.length - 1] = {
              ...temp,
              isLoading: false,
            };
          }
          return newChatData;
        });
        highlightCode();
        setBtnLoading(false);
      }

      if (data.status && data.status === 50001) {
        const errorMsg = getRandomError();
        message.error(errorMsg);
      }

      // hljs.initHighlighting();
    };

    ws.onerror = function (error: any) {
      setBtnLoading(false);
      console.log("wserror", error);
    };
  };

  const unConnectWs = () => {
    if (ws) {
      ws.close();
    }
  };

  useEffect(() => {
    // 是否关联知识库，获取超级助理传递过来的参数
    const state = location.state;
    if (state && state.knowledge) {
      setKnowledgeList(JSON.parse(state.knowledge));
    }
  }, []);

  useEffect(() => {
    const data = localStorage.getItem("chatWorkFlowData")
      ? JSON.parse(localStorage.getItem("chatWorkFlowData") as string)
      : {};
    if (!data.id) {
      setIsHistoryWokeFlow(true);
    }
  }, []);

  const goChatBottom = () => {
    if (boxRef?.current) {
      boxRef.current.scrollTop = boxRef?.current?.scrollHeight;
    }
  };

  const getTopic = (fn: any) => {
    toCreateModal({
      llm_id: current.id,
      name: current.name,
      model_ver_id: 1040,
    }).then((res: any) => {
      if (res.data && res.data.status === 200) {
        const data = res.data.data;
        setTopicId(data.id);
        const url = `/superAssistantChat?topicId=${data.id}`;
        window.history.replaceState({ url: url }, "", url);
        fn && fn(data.id);
      }
    });
  };

  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  useEffect(() => {
    const onScroll = () => {
      const { scrollTop } = boxRef?.current;
      if (scrollTop > lastScrollTop) {
        setIsScrollingUp(false); // 用户向下滚动
      } else {
        setIsScrollingUp(true); // 用户向上滚动
      }
      setLastScrollTop(scrollTop);
    };

    boxRef?.current.addEventListener("scroll", onScroll);

    return () => {
      boxRef && boxRef?.current?.removeEventListener("scroll", onScroll);
    };
  }, [lastScrollTop]);

  const [isOnBottom, setIsOnBottom] = useState(false);

  useEffect(() => {
    setIsOnBottom(true);
    if (boxRef?.current && boxRef.current?.scrollTop !== undefined) {
      boxRef.current.scrollTop = boxRef.current?.scrollHeight;
    }
  }, []);

  useEffect(() => {
    const handleScrollFn = () => {
      const { scrollTop, scrollHeight, clientHeight } = boxRef?.current;
      const errorTolerance = 80; // 设置一个误差容忍值
      if (
        errorTolerance >= Math.abs(scrollTop - (scrollHeight - clientHeight))
      ) {
        setIsOnBottom(true); // 此时已经在底部了
      } else {
        setIsOnBottom(false);
      }
    };

    boxRef?.current?.addEventListener("scroll", handleScrollFn);

    return () => {
      boxRef && boxRef?.current?.removeEventListener("scroll", handleScrollFn);
    };
  }, []);
  const state = location.state;
  let superType = "llm_chat";
  if (state?.pattern === "super_assistant") {
    superType = "super_assistant";
  }

  const chatMode = superType;

  useEffect(() => {
    let boxScrollBottom: any = null;
    // 根据滚动方向进行相应处理

    if (!isScrollingUp && isOnBottom) {
      if (boxRef?.current) {
        boxScrollBottom = setInterval(() => {
          if (
            boxRef.current?.scrollTop === undefined ||
            boxRef.current?.scrollTop === null
          ) {
            return;
          }
          boxRef.current.scrollTop = boxRef.current?.scrollHeight;
        }, 100);
      }
    } else {
      clearInterval(boxScrollBottom);
    }

    return () => {
      if (boxScrollBottom) {
        clearInterval(boxScrollBottom);
      }
    };
  }, [chatData, isScrollingUp, isOnBottom]);

  const sendMsg = () => {
    if (btnLoading) {
      return;
    }

    if (!chatMsg) {
      return false;
    }

    if (!token) {
      onLoginAndRegister();
      return;
    }

    const newChatData = [...chatData];

    const sendMsgWs = (id?: any) => {
      // llm_chat 简洁和  assistant 专家模式
      if (state?.pattern === "super_assistant") {
        superType = "super_assistant";
      }
      let msgObj: any = {
        message: chatMsg,
        topic_id: id || topicId,
        history: newChatData.slice(0, newChatData.length).slice(-10),
        is_save_msg: token ? true : false,
        chat_mode: chatMode,
        is_stream: true,
        enable_web_search: internetActive ? true : false,
        model: isDP ? "deepseek-r1-vol" : "gpt-4o",
      };

      const curChatFiles = localStorage.getItem("chatFiles")
        ? JSON.parse(localStorage.getItem("chatFiles") as string)
        : [];
      msgObj.message = [
        {
          type: "text",
          text: chatMsg,
        },
        ...(!isDP
          ? curChatFiles
              .concat(chatFiles)
              .filter(
                (item: any) =>
                  item.status === "done" &&
                  item.response?.data &&
                  item.response?.data.file_url
              )
              .map((item: any) => {
                const fileType = item.name.substring(
                  item.name.lastIndexOf(".")
                );
                return {
                  name: item.name,
                  status: item.status,
                  type: [".jpg", ".jpeg", ".png", ".webp"].includes(fileType)
                    ? "image_url"
                    : [
                        ".pdf",
                        ".docx",
                        ".xlsx",
                        ".pptx",
                        ".txt",
                        ".md",
                        ".csv",
                      ].includes(fileType)
                    ? "file_url"
                    : [".mp3", ".wav"].includes(fileType)
                    ? "audio_url"
                    : "video_url",
                  url: item.response?.data?.file_url,
                  fmt: fileType,
                };
              })
          : []),
      ];
      localStorage.removeItem("chatFiles");

      // 是否关联知识库，增加
      if (knowledgeList.length > 0) {
        if (isDP) {
          msgObj.knowledge_folder = [];
        } else {
          let result = knowledgeList.map(({ name, ...rest }: any) => rest);
          msgObj.knowledge_folder = result;
        }
      }

      newChatData.push(
        {
          role: 1,
          content: msgObj?.message,
        },
        {
          role: 2,
          content: " ",
          isLoading: true,
        }
      );

      const sendAction = () => {
        if (ws.readyState === 1) {
          //开始通信时的处理
          console.log("msgObj", msgObj);
          ws.send(JSON.stringify(msgObj));
        }
      };

      if (ws && ws.readyState === 1) {
        sendAction();
      } else {
        unConnectWs();
        setTimeout(() => {
          connectWs(() => {
            sendAction();
          });
        });
      }
    };

    if (!topicId) {
      getTopic(sendMsgWs);
    } else {
      sendMsgWs();
    }

    setChatData(newChatData);

    setChatMsg("");
    setFileList([]);
    setPastedFiles([]);
    setDeleteFileList([]);
  };

  const [relatedClick, setRelatedClick] = useState(false);
  const onRelatedClick = (message: string) => {
    setChatMsg(message);
    setRelatedClick((value) => !value);
    goChatBottom();
  };

  useEffect(() => {
    sendMsg();
  }, [relatedClick]);

  useEffect(() => {
    if (chatData?.length) {
      const clickHandler = (event: any) => {
        if (!event.target.getAttribute("href")) {
          return;
        }
        // 阻止 a 标签点击事件的默认行为，即页面跳转
        event.preventDefault();
        window.open(event.target.getAttribute("href"), "_blank");
      };

      var content: any = document.getElementById("history");

      const aTags = content.getElementsByTagName("a");

      // 借用 Array.prototype.forEach 遍历处理 选到的 a 标签
      Array.prototype.forEach.bind(aTags)((a) => {
        a.addEventListener("click", clickHandler);
      });
    }
  }, [chatData?.length]);

  useEffect(() => {
    // 如果是从历史记录进入的，需要重新连接ws
    if (topicId) {
      unConnectWs();
      connectWs();
    }
  }, []);

  return (
    <div className={classnames(Styles.chat)}>
      {isShareModalVisible ? (
        <ShareModal
          open={isShareModalVisible}
          setOpen={handleShare}
          url={shareUrl}
          shareTitle={shareTitle}
        />
      ) : null}
      <div className={Styles.chat_inner}>
        <div className={Styles.chat_history} ref={boxRef}>
          {chatData && !!chatData.length ? (
            <div
              className={Styles.chat_history_inner}
              id="history"
              style={
                chatFiles.length && chatFiles.length > 3
                  ? {
                      marginBottom: "150px",
                    }
                  : chatFiles.length > 6
                  ? { marginBottom: "200px" }
                  : {}
              }
            >
              {chatData.map((item: any, index: number) => {
                return (
                  <div
                    className={classnames(
                      Styles.chat_history_item,
                      Styles.chat_history_item_receive
                    )}
                    key={index}
                  >
                    {item.role === 2 ? (
                      <div className={Styles.chat_history_name_box}>
                        <img
                          className={Styles.chat_history_item_img}
                          src={agentB_proBg}
                          loading="lazy"
                        ></img>
                        <div>超级助理</div>
                      </div>
                    ) : null}
                    {item.content && (
                      <div className={Styles.chat_history_item_msg}>
                        {item.role === 2 ? (
                          <div
                            className={Styles.chat_history_item_content_wrap}
                            style={{
                              whiteSpace: "pre-wrap",
                              width: "calc(100% - 32px)",
                            }}
                          >
                            <Content
                              handleSetIsShareModalVisible={handleShare}
                              curWidth={boxRef.current?.clientWidth}
                              message={item}
                              isHistoryWokeFlow={isHistoryWokeFlow}
                              onRelatedClick={onRelatedClick}
                              isLast={index === chatData.length - 1}
                              tryAgain={() => {
                                tryAgain(index, item);
                              }}
                              btnLoading={item.isLoading}
                              topicId={topicId}
                              chatMode={chatMode}
                              showShareBtn
                            />
                          </div>
                        ) : (
                          <div className={Styles.chat_history_item_wrapper}>
                            <div className={Styles.chat_history_user_msg_item}>
                              <div className={Styles.chat_history_item_inner}>
                                <div
                                  style={{
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  {typeof item.content === "string" ? (
                                    item.content
                                  ) : (
                                    <>
                                      {
                                        item?.content?.find(
                                          (subItem: any) =>
                                            subItem.type === "text"
                                        )?.text
                                      }
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            {Array.isArray(item?.content) && (
                              <div
                                className={Styles.chat_history_user_msg_item}
                                style={{ justifyContent: "flex-start" }}
                              >
                                {item?.content?.filter(
                                  (subItem: any) => subItem.type !== "text"
                                ).length ? (
                                  <div
                                    className={Styles.filesList}
                                    style={{
                                      position: "relative",
                                      top: "16px",
                                    }}
                                  >
                                    <UploadFilesList
                                      fileList={item?.content?.filter(
                                        (subItem: any) =>
                                          subItem.type !== "text"
                                      )}
                                      isChat={true}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={classnames(Styles.empty)}></div>
          )}
        </div>
        <div className={`${Styles.chat_insert}`}>
          <CustomInput
            onChat={(value: string, fileList: any) => {
              setChatMsg(value);
              setSendNow(true);
              setFileList(fileList);
            }}
            isInSuperAssistant={true}
            chatWorkflowSend={chatWorkflowSend}
            setKnowledgeList={setKnowledgeList}
            knowledgeList={knowledgeList}
            isOnBottom={isOnBottom}
            goChatBottom={goChatBottom}
            isLoading={btnLoading}
            onStopChatClick={onStopChatClick}
            inSuperAssistantIsDP={isDP}
            inSuperAssistantInternetActive={internetActive}
          ></CustomInput>
        </div>

        {isShowDynamicForm ? (
          <DynamicForm
            isShowWorkForm={isShowDynamicForm}
            clearDynamicForm={clearDynamicForm}
            userInputForm={userInputForm}
            onSend={chatWorkflowSend}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ChatBox;
